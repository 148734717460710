import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  :root {
    --pink: #EE2880;
    --darkgrey: #4A4949;
    --text: #565656;
    --lightblue: #f4f7fc7a;
    --rt-color-white: #fff;
    --rt-color-dark: #EE2880;
    --rt-color-success: #8dc572;
    --rt-color-error: #be6464;
    --rt-color-warning: #f0ad4e;
    --rt-color-info: #337ab7;
    --rt-opacity: 0.9;
  }

  :root {
    --rt-color-white: #fff;
    --rt-color-dark: #222;
    --rt-color-success: #8dc572;
    --rt-color-error: #be6464;
    --rt-color-warning: #f0ad4e;
    --rt-color-info: #337ab7;
    --rt-opacity: 0.9;
  }


  .CookieConsent div {
    font-family: 'Catamaran', sans-serif !important;
    font-size: 13px;
  }

  .CookieConsent button {
    font-family: 'Catamaran', sans-serif !important;
    appearance: none;
    background-color: #fff !important;
    border-radius: 4px !important;
  }

  .CookieConsent button#rcc-decline-button {
    color: #fff !important;
    background-color: #5B072C !important;
  }



  .react-tooltip__tooltip_KtSkBq {
    visibility: hidden;
    width: max-content;
    pointer-events: none;
    opacity: 0;
    will-change: opacity, visibility;
    border-radius: 3px;
    padding: 8px 16px;
    transition: opacity .5s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
  }
  .react-tooltip__fixed_KtSkBq {
    position: fixed;
  }
  .react-tooltip__arrow_KtSkBq {
    background: inherit;
    width: 24px;
    height: 24px;
    position: absolute;
    transform: rotate(45deg);
  }
  .react-tooltip__no-arrow_KtSkBq {
    display: none;
  }
  .react-tooltip__clickable_KtSkBq {
    pointer-events: auto;
  }
  .react-tooltip__show_KtSkBq {
    visibility: visible;
    opacity: var(--rt-opacity);
  }
  .react-tooltip__dark_KtSkBq {
    background: var(--rt-color-dark);
    color: var(--rt-color-white);
  }
  .react-tooltip__light_KtSkBq {
    background-color: var(--rt-color-white);
    color: var(--rt-color-dark);
  }
  .react-tooltip__success_KtSkBq {
    background-color: var(--rt-color-success);
    color: var(--rt-color-white);
  }
  .react-tooltip__warning_KtSkBq {
    background-color: var(--rt-color-warning);
    color: var(--rt-color-white);
  }
  .react-tooltip__error_KtSkBq {
    background-color: var(--rt-color-error);
    color: var(--rt-color-white);
  }
  .react-tooltip__info_KtSkBq {
    background-color: var(--rt-color-info);
    color: var(--rt-color-white);
  }


  ::-moz-selection { /* Code for Firefox */
    background: rgba(253, 233, 242, 0.75);
  }

  ::selection {
    background: rgba(253, 233, 242, 0.75);
  }

  * {
    margin: 0;
    font-family: 'Playfair Display';
  }

  a:focus {
    outline: 0;
  }

  html,
  body {
    height: 100%;
    background-color: #ffffff;
    max-width: 100vw;
  }

  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    position: relative;
  }

  body.news-page nav a.news {
    color: var(--pink) !important;
    font-weight: bold !important;
     @media(max-width: 880px) {
      color: #fff !important;
    }
  }

  body.person-page nav a.people {
    color: var(--pink) !important;
    font-weight: bold !important;
    @media(max-width: 880px) {
      color: #fff !important;
    }
  }


  body.artist-page nav a.artists {
    color: var(--pink) !important;
    font-weight: bold !important;
    @media(max-width: 880px) {
      color: #fff !important;
    }
  }

  img,
  picture,
  video,
  canvas,
  svg {
    display: block;
    max-width: 100%;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }

  #root,
  #__next {
    isolation: isolate;
  }

  em, strong, ol li {
    font-family: Catamaran, sans-serif;
  }

  em {
    font-weight: 400;
  }

  article a {
    color: var(--pink);
  }

  ol {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  ol li {
    font-size: 18px;
    line-height: 28px;
    color: var(--text);
  }

  blockquote {
    font-size: 18px;
    line-height: 28px;
    margin-left: 46px;
    border-left: 3px solid var(--pink);
    font-family: Catamaran, sans-serif;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 16px;
    color: var(--text);
  }

  blockquote em {
  }

  blockquote + p {
    margin-top: 1rem;
  }

  p + p + blockquote {
    margin-top: 1rem;
  }

  h1 {
    font-family: 'Playfair Display', serif;
    color: var(--pink);
    font-size: 81px;
    line-height: 91px;
    @media (max-width: 880px) {
      font-size: 36px;
      line-height: 44px;
    }
  }

  h2 {
    font-family: 'Catamaran', sans-serif;
    color: var(--text);
    font-size: 68px;
    line-height: 66px;
    font-weight: 100;
    @media (max-width: 880px) {
      font-size: 34px;
      line-height: 38px;
    }
  }

  h3 {
    font-family: 'Catamaran', sans-serif;
    color: var(--text);
    font-size: 43px;
    line-height: 52px;
    font-weight: 100;
    @media (max-width: 880px) {
      font-size: 25px;
      line-height: 30px;
    }
  }

  h4 {
    font-family: 'Catamaran', sans-serif;
    color: var(--text);
    font-size: 32px;
    line-height: 44px;
    font-weight: 100;
    @media (max-width: 420px) {
      font-size: 26px;
      line-height: 24px;
    }
  }

  h5, h6 {
    font-family: Catamaran, sans-serif;
    font-weight: 300;
  }

  #lg-counter-all, #lg-counter-current {
    font-family: Catamaran, sans-serif;
  }

  .lg-sub-html {
    font-family: Catamaran, sans-serif;
  }

  .lg-sub-html span {
    font-family: Catamaran, sans-serif;
  }

 .lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
    border-color: var(--pink) !important;
  }

  p.intro {
    font-family: 'Catamaran', sans-serif;
    color: var(--text);
    font-size: 27px;
    line-height: 34px;
    font-weight: 100;
    @media (max-width: 420px) {
      font-size: 17px;
      line-height: 26px;
    }
  }

  nav a, h1 a, h2 a, h3 a, h4 a, h5 a, p a, footer a {
    transition: color 0.25s ease-in-out;
  }

  nav a:hover, h1 a:hover, h2 a:hover, h3 a:hover h4 a:hover, h5 a:hover p a:hover, footer a:hover {
    color: var(--pink);
  }

  p {
    font-family: 'Catamaran', sans-serif;
    color: var(--text);
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    @media (max-width: 420px) {
      font-size: 17px;
      line-height: 26px;
    }
  }

  .embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  p + p {
    margin-top: 1rem;
  }

  p + h3 {
    margin-top: 4rem;
    margin-bottom: 0rem !important;
  }

  p a {
    font-family: 'Catamaran', sans-serif;
    text-decoration: none;
    font-weight: bold;
  }

  p span {
      font-family: Catamaran, sans-serif;
  }

  body.artist-page nav.menu li a.artists {
    font-weight: 600;
  }

  body.news-page nav.menu li a.news {
    font-weight: 600;
  }

  .no-print {
    @media print {
      display: none !important;
    }
  }

  .print-only {
    display: none;
    @media print {
      display: block;
    }
  }

@page {
  size: A4;
  margin: 30mm;
}

@media print {
  html, body {
  }
}

@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}

  main a {
    color: var(--pink);
  }

`;

export default GlobalStyle;
